export default [{
    header: "Dashboards",
    icon: "HomeIcon",
    children: [{
            title: "Vormerkungen",
            route: "dashboard/nextinspections",
            icon: "ShoppingCartIcon"
        },
        {
            title: "Mängel",
            route: "dashboard-analytics",
            icon: "ActivityIcon"
        },
        {
            title: "Prüfungen",
            route: "dashboard-analytics",
            icon: "ActivityIcon"
        },
        {
            title: "Stammdaten",
            icon: "FileTextIcon",
            children: [{
                    title: "Standorte",
                    route: "apps-invoice-list"
                },
                {
                    title: "Eigentümer",
                    route: "apps-invoice-list"
                },
                {
                    title: "Arbeitgeber",
                    route: "apps-invoice-list"
                },
            ]
        },
    ]
}]