export default [{
    header: "Ex",
    icon: "PackageIcon",
    children: [{
            title: "Anlagen",
            route: "apps-email",
            icon: "MailIcon"
        },
        {
            title: "Prüfungen",
            icon: "FileTextIcon",
            children: [{
                    title: "Übersicht",
                    route: "apps-invoice-list"
                },
            ]
        },
        {
            title: "Mängel",
            icon: "FileTextIcon",
            children: [{
                    title: "Übersicht",
                    route: "apps-invoice-list"
                },
            ]
        }
    ]
}]